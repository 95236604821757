import React from "react"
import { graphql } from "gatsby"
import RichTextRenderer from "../components/RichTextRenderer"
import Link from "../components/Link"
import {
  Section,
  theme,
  Container,
  GridContainer,
  Box,
  Card,
  Flex,
  Heading,
  Button,
} from "@singita/components"
import { Translation } from "react-i18next"
import GridItem from "../components/GridItem"
import ImageWrapper from "../modules/ImageWrapper"
import { useSeo } from "gatsby-singita-theme/src/hooks"
import HelmetWrapper, { getJsonLd } from "gatsby-singita-theme/src/components/Helmet"
import { LocaleLink } from "gatsby-singita-theme/src/components/LocaleLink"
import Main from "gatsby-singita-theme/src/components/Main"
import { createSlug } from "../common/utils"
import styled from "@emotion/styled"
import { TEMPLATE_TYPES } from "gatsby-singita-theme/src/common/constants"

const LODGE_ACTIVITY_DATA_MAP = {
  title: ["headline"],
  image: ["images", "fluid", "src"],
  altText: ["headline"],
}

const LodgeActivityTemplate = props => {
  const {
    data: { activity },
  } = props
  const context = props.pageContext
  const lodge = activity.lodge.find(item => item.slug === context.slug)
  const seoProps = useSeo(activity, LODGE_ACTIVITY_DATA_MAP)
  seoProps.title = `${activity.headline} at ${lodge.fullTitle} | African Safari | Singita`
  seoProps.description = `${seoProps.description} at ${lodge.fullTitle}.`
  seoProps.location = lodge.fullTitle
  const jsonLd = getJsonLd(seoProps, TEMPLATE_TYPES.EXPERIENCE)
  
  const StyledHeader = styled(Flex)`
    position: sticky;
    top: 70px;
    left: 0;
    border-bottom: 1px solid ${theme.colors.baseGrayVeryLight};
    z-index: 1000;
    align-items: center;
    background-color: ${theme.colors.bgLighter};
  `

  const styles = {
    sectionContainer: {
      bg: "midGrey",
      borderBottomWidth: "3px",
      borderColor: "lightGrey",
      borderStyle: "solid",
      borderWidth: 0,
      gridGap: `${theme.space[4]}px`,
      gridTemplateColumns: [
        "repeat(2, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
        "repeat(4, 1fr)",
      ],
      width: "100%",
    },
    sectionItem: {},
  }

  let links
  if (activity?.links) {
    links = activity.links.map(link => {
      return <Link {...link} />
    })
  }
  return (
    <Main>
      <Container pt={[8]}>
        <HelmetWrapper {...seoProps} schemaJsonLd={jsonLd} />
        <StyledHeader py={[2]}>
          <Flex
            maxWidth={"1280px"}
            justifyContent="space-between"
            width="100%"
            mx="auto"
            alignItems="baseline"
          >
            <Box>
              <Heading size="h6">{lodge.meta.activitiesTitle}</Heading>
            </Box>
            <LocaleLink
              data-testid={`lodge-activity`}
              to={`${context.basePath}/`}
            >
              <Button variant="text" icon="close" iconPosition="right">
                <Translation>{t => t("Close")}</Translation>
              </Button>
            </LocaleLink>
          </Flex>
        </StyledHeader>
        <Section
          headline={`${activity.headline} at ${lodge.fullTitle}`}
          direction="row"
          textContainerSx={{ pr: [0, 6], top: "108px" }}
          renderCta={links}
          headlineSize="h1"
        >
          <RichTextRenderer content={activity.content} color={lodge.color} />
        </Section>
        <Section direction="column" headline={lodge.meta.otherActivitiesTitle}>
          <GridContainer sx={{ ...styles.sectionContainer }}>
            {lodge.activities
              .filter(
                ({ contentful_id }) => contentful_id !== activity.contentful_id
              )
              .map((activity, index) => {
                return (
                  <LocaleLink
                    data-testid={`lodge-activity-${index}`}
                    target="_blank"
                    to={`${context.basePath}/experience-${createSlug(
                      activity.headline
                    )}/`}
                    state={{
                      isModal: false,
                      anchor: activity.id,
                    }}
                  >
                    <GridItem
                      key={activity.contentful_id}
                      index={index}
                      columns={[2, 2, 3, 4]}
                    >
                      <Box
                        key={activity.id}
                        sx={{ ...styles.sectionItem, cursor: "pointer" }}
                      >
                        <Card
                          variant="small"
                          headline={activity.headline}
                          isLink={true}
                          image={
                            <ImageWrapper
                              image={activity.images}
                              from="Activity"
                            />
                          }
                        />
                      </Box>
                    </GridItem>
                  </LocaleLink>
                )
              })}
          </GridContainer>
        </Section>
      </Container>
    </Main>
  )
}

export default LodgeActivityTemplate

export const LODGE_INFORMATION_QUERY = graphql`
  query lodgeActivities($contentful_id: String) {
    activity: contentfulActivity(contentful_id: { eq: $contentful_id }) {
      headline
      id
      tag
      contentful_id
      headline
      content {
        raw
        references {
          ...RichTextReferences
          ... on ContentfulContent {
            ...Content
          }
        }
      }
      images {
        fluid(quality: 90, maxWidth: 400, maxHeight: 260) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      links {
        __typename
        ...LinkFields
      }
      structuredData {
        headline
        description {
          description
        }
        image {
          fluid(quality: 90, maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        altText
      }
      lodge {
        fullTitle
        slug
        meta {
          otherActivitiesTitle
          activitiesTitle
        }
        activities {
          contentful_id
          headline
          images {
            fluid(quality: 90, maxWidth: 400, maxHeight: 260) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
